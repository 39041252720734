/* eslint-disable prettier/prettier */
import React, { createContext, useState } from 'react';
export const StoreMgmtContext = createContext<any>('');
export function StoreMgmtContextProvider(props: any) {
    const [createTaskModal, setcreateTaskModal] = useState(false)
    const [TaskandGoals, setTaskandGoals] = useState('');

    return (
        <div>
            <StoreMgmtContext.Provider
                value={{
                    createTaskModal,
                    setcreateTaskModal,
                    TaskandGoals,
                    setTaskandGoals
                }}
            >
                {props.children}
            </StoreMgmtContext.Provider>
        </div>
    );
}
