/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { createContext } from 'react';
import { Router } from 'react-router';
//import { History } from 'history';
// eslint-disable-next-line @typescript-eslint/no-var-requires
import {
  RACThemeProvider,
  makeStyles,
  StylesProvider,
  createGenerateClassName,
} from '@rentacenter/racstrap';
import clsx from 'clsx';
import { ErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary';
import { InContainerAuthProvider } from '../context/auth/InContainerAuthProvider';
import { StandaloneAuthProvider } from '../context/auth/StandaloneAuthProvider';
import { StoreMgmtContextProvider } from '../context/storeMgmtContext';
import { AuthGuard } from '../components/AuthGuard/AuthGuard';
import { CustomPropInterface } from '../../src/index';
//import { History } from 'history';

const History = require('history');

interface Props {
  history: typeof History;
  customProps?: CustomPropInterface;
}

export const appTestId = 'appTestId';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
  },
}));

const generateClassName = createGenerateClassName({
  seed: 'RAC-STOREMGMT-',
});
export const ContainerContext = createContext<CustomPropInterface | undefined>(
  undefined
);

export const App = ({ history, customProps }: Props) => {
  /* eslint-disable no-console */
  console.log('Inside AppTSX', history);
  console.log('storeMgmtWindow', window);
  const { isRenderedByContainer } = window;
  /* eslint-disable no-console */
  console.log('Container Render', { isRenderedByContainer });
  const classes = useStyles();

  return (
    <div data-testid={appTestId}>
      <StylesProvider generateClassName={generateClassName}>
        <RACThemeProvider>
          <ContainerContext.Provider value={customProps}>
            <StoreMgmtContextProvider>
              <div className={clsx(classes.container)}>
                <ErrorBoundary>
                  <Router history={history}>
                    {isRenderedByContainer ? (
                      <InContainerAuthProvider>
                        <AuthGuard />
                      </InContainerAuthProvider>
                    ) : (
                      <StandaloneAuthProvider>
                        <AuthGuard />
                      </StandaloneAuthProvider>
                    )}
                  </Router>
                </ErrorBoundary>
              </div>
            </StoreMgmtContextProvider>
          </ContainerContext.Provider>
        </RACThemeProvider>
      </StylesProvider>
    </div>
  );
};
