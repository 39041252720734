/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
import {
  client,
  clientCall,
  clientApiCall,
  defaultClient,
  getJWToken,
  clientCallManageRoute,
  client1
} from "./client";
import { appConfig } from "../config/app-config";
import { CancelToken } from "axios";
import jwtDecode from "jwt-decode";
// import { ProductPermissions } from '../constants/constants';
import { User } from "../types/types";

const InventoryBaseUrl = appConfig.apiUrls.inventory;
const cashmgmtDevUrl = appConfig.apiUrls.cashmgmt;
const agreementDevUrl = appConfig.apiUrls.agreement;
const menuDevUrl = appConfig.apiUrls.menu;
// const customerBaseUrl = appConfig.apiUrls.customer;
// const dashboardBaseUrl = appConfig.apiUrls.dashboard;
export const getCurrentUser = (): Promise<User> =>
  defaultClient(
    "users/current",
    { method: "GET" },
    appConfig.apiUrls.container,
    true
  );

export const getUserPreferences = (productType: string) =>
  defaultClient(
    `users/current/preferences/${productType}`,
    { method: "GET" },
    appConfig.apiUrls.container
  );

export const getUserPermissions = () => {
  return [];
};
export const getRouteDetails = async (storeNumber: any) => {
  /* eslint-disable no-console */
  try {
    return await clientCall(
      `route/details/${storeNumber}`,
      { method: "GET" },
      appConfig.apiUrls.micro,
      "jj",
      true
    );
  } catch (err: any) {
    return err;
    //throw err;
  }
};
// FDC Functions Start
export const GetCustomerinfo = async (agreementId: any) => {
  const TempAccessToken:any = "QAToken"
  return await clientCall(
    `agreement/customers/${agreementId}`,
    {
      method: "GET",
    },
    appConfig.apiUrls.agreement,
    TempAccessToken,
    true
  );
};
export const CheckSign = async (agreementId: string) => {
  const TempAccessToken:any = "QAToken"
  return await client1(
    `agreement/delivery-receipt/sign/check/${agreementId}`,
    { method: 'GET' },
    appConfig.apiUrls.agreement,
    TempAccessToken
  );
};
export const Recieptsign = async (updatesign: any) => {
  const TempAccessToken = "QAToken"
  return await clientCall(
    `agreement/delivery/receipt/sign`,
    { method: "PUT", body: updatesign },
    appConfig.apiUrls.agreement,
    TempAccessToken
  );
};
export const GetEmployeeID = async () => {
  const TempAccessToken = "QAToken";
  return await clientCall(
    `user/current`,
    { method: "GET" },
    menuDevUrl,
    TempAccessToken,
    true
  );
};
export const GenerateAgreementDoc = async (Payload: any) => {
  const TempAccessToken = "QAToken";
  return await clientCall(
    `agreement/document/generate`,

    { method: "POST", body: Payload },
    appConfig.apiUrls.agreement,
    TempAccessToken,
    true
  );
};
export const getPrintDocument = async (payload: any) => {
  const TempAccessToken = "QAToken";
  return await clientCall(
    `agreement/document/print`,
    { method: "POST", body: payload },
    appConfig.apiUrls.agreement,
    TempAccessToken,
    true
  );
};
export const FinishdeliveryStatus = async (complete: any) => {
  const TempAccessToken = "QAToken";
  return await clientCall(
    `agreement/delivery`,
    { method: 'POST', body: complete },
    appConfig.apiUrls.agreement,
    TempAccessToken
  );
};
export const GetRole = async (payload: any) => {
  const TempAccessToken = "QAToken";
  return await clientCall(
    `menu-and-stores`,
    { method: "POST", body: payload },
    menuDevUrl,
    TempAccessToken,
    true
  );
};
export const GetCustomerId = async (GCID: string) => {
  const TempAccessToken = "QAToken";
  try {
    return await clientCall(
      `customer/${GCID}/detail`,
      { method: "GET" },
      appConfig.apiUrls.customer,
      TempAccessToken
    );
  } catch (err: any) {
    /* eslint-disable no-console */
    console.log(err);
    /* eslint-enable no-console */
    return "Error";
  }
};
export const GenerateSwitchoutReceipt = async (bodyobj: any) => {
  const TempAccessToken = "QAToken";

  return await clientCall(
    `agreement/generatereceipt`,
    { method: "POST", body: bodyobj },
    appConfig.apiUrls.agreement,
    TempAccessToken
  );
};
// FDC Functions end---------------------------------------------
export const ManageRoute = async (payload: any) => {
  const TempAccessToken = "QAToken";
  try {
    return await clientCallManageRoute(
      `route/manage`,
      { method: "POST", body: payload },
      appConfig.apiUrls.micro,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const GetCoworkerDropDown = async (StoreNumber: any) => {
  // eslint-disable-next-line no-console
  console.log("appConfig agreement", appConfig.apiUrls.agreement);
  return await client(
    `agreement/options/12345?storeNumber=${StoreNumber}&isSwitchOut=1`,
    { method: "GET" },
    appConfig.apiUrls.agreement
  );
};
export const getmenuandstore = async () => {
  try {
    const jwtToken = await getJWToken();
    const decodedToken: any = jwtDecode(jwtToken);
    // eslint-disable-next-line no-console
    console.log("decodedToken", decodedToken);
    const parameters = {
      coworkerId: decodedToken["custom:employeeId"],
    };
    return await client(
      "menu-and-stores",
      { method: "POST", body: parameters },
      menuDevUrl
    );
  } catch (e: any) {
    return null;
  }
};

export const setUserPreferences = (
  productType: string,
  payload: Record<string, any>,
  cancelToken?: CancelToken
) =>
  client(
    `users/current/preferences/${productType}`,
    {
      method: "PATCH",
      body: [payload],
      cancelToken,
    },
    appConfig.apiUrls.container
  );

export const getUserStores = () =>
  client(
    "users/current/stores?q=permissions[:includes]=RACPAD_DAP",
    { method: "GET" },
    appConfig.apiUrls.container
  );
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SendAccessLink = async (UserInfo: any) => {
  const Headers = {
    phoneNumber:
      UserInfo.phoneNumber !== "" &&
      UserInfo.phoneNumber !== undefined &&
      UserInfo.phoneNumber !== null
        ? UserInfo.phoneNumber
        : "",
    emailAddress:
      UserInfo.emailAddress !== "" &&
      UserInfo.emailAddress !== undefined &&
      UserInfo.emailAddress !== null
        ? UserInfo.emailAddress
        : "",
    isOnlyGenerateToken: "false",
  };
  return await clientApiCall(
    `sendaccesslink`,
    { method: "POST", body: UserInfo, headers: Headers },
    appConfig.apiUrls.store,
    "AccessToken",
    false
  );
};
export const updatePinDetails = async (payload: any) => {
  /* eslint-disable no-console */
  // console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = "QAToken";
    return await client(
      `pin/manage`,
      { method: "POST", body: payload },
      // BaseUrl,
      appConfig.apiUrls.dashboard,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const GetCloseEndOfDayDetails = async (payload: any) => {
  const responseObj: any = {
    getDrawerDetail: "",
    getPendingEvents: "",
    getPendingCustomerAgreementTransfer: "",
    getTransferReceiveGridDetails: "",
    getPendingAgreementTransfer: "",
  };
  try {
    await Promise.all([
      // GetCustomerInfoOptions(getDrawerDetail),
      // GetCustomerByIdInfo(customerId, storeNumber),
      getDrawerDetail(payload),
      getPendingEvents(payload),
      getPendingCustomerAgreementTransfer(payload),
      getTransferReceiveGridDetails(payload),
      getPendingAgreementTransfer(payload),
    ])
      .then((response) => {
        [
          responseObj.getDrawerDetail,
          responseObj.getPendingEvents,
          responseObj.getPendingCustomerAgreementTransfer,
          responseObj.getTransferReceiveGridDetails,
          responseObj.getPendingAgreementTransfer,
        ] = response;
      })
      .catch((resp) => {
        // eslint-disable-next-line no-console
        console.log("responseObj", resp);
      });
    return responseObj;
  } catch (e: any) {
    return responseObj;
  }
};
export const getPendingAgreementTransfer = async (payload: any) => {
  try {
    const TempAccessToken = "QAToken";
    return await client(
      `cashmgmt/drawer/audit/info`,
      { method: "POST", body: payload },
      appConfig.apiUrls.cashmgmt,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const getTransferReceiveGridDetails = async (payload: any) => {
  try {
    const TempAccessToken = "QAToken";
    return await client(
      `inventory/transfer/list/${payload.storeNumber}?setNumber=${payload.setNumber}&type=${payload.type}`,
      { method: "GET" },
      InventoryBaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const getDrawerDetail = async (payload: any) => {
  /* eslint-disable no-console */ /* eslint-enable no-console */ /* eslint-enable no-console */ try {
    const TempAccessToken = "QAToken";
    return await client(
      `manage/reconcil`,
      { method: "POST", body: payload },
      cashmgmtDevUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const getDropDocument = async (payload: any) => {
  /* eslint-disable no-console */
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = "QAToken";
    return await client(
      `drop/document`,
      { method: "POST", body: payload },
      cashmgmtDevUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const getConfigDetails = async (payload: any) => {
  try {
    const TempAccessToken = "QAToken";
    return await client(
      `store-details`,
      { method: "POST", body: payload },
      appConfig.apiUrls.storemgmt,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const GetStatsStatus = async (payload: any) => {
  const TempAccessToken = "QAToken";
  return await clientCall(
    `store-details`,
    { method: "POST", body: payload },
    appConfig.apiUrls.micro,
    TempAccessToken,
    true
  );
};
export const manageAgreementTransaction = async (payload: any) => {
  /* eslint-disable no-console */
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = "QAToken";
    return await client(
      `manage/transfer`,
      { method: "POST", body: payload },
      agreementDevUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};

export const getPendingEvents = async (payload: any) => {
  try {
    const TempAccessToken = "QAToken";
    return await client(
      `manage/drop`,
      { method: "POST", body: payload },
      cashmgmtDevUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const getPendingCustomerAgreementTransfer = async (payload: any) => {
  try {
    const TempAccessToken = "QAToken";
    return await client(
      `agreement/transfers/${payload.storeNumber}?transferType=${payload.transferType}&offset=${payload.offset}`,
      { method: "GET" },
      appConfig.apiUrls.agreement,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const getPinDetails = async (Payload: any) => {
  try {
    return await clientCall(
      `coworker`,
      { method: "POST", body: Payload },
      appConfig.apiUrls.micro,
      "kjiokljlk",
      true
    );
  } catch (e: any) {
    return null;
  }
};
export const getprofileaccess = async (Payload: any) => {
  try {
    return await clientCall(
      `store-access`,
      { method: "POST", body: Payload },
      appConfig.apiUrls.micro,
      "kjhhjkhkj",
      true
    );
  } catch (e: any) {
    return null;
  }
};
export const getsecondfactor = async (Payload: any) => {
  try {
    return await clientCall(
      `coworker-authentication`,
      { method: "POST", body: Payload },
      appConfig.apiUrls.micro,
      "kjhjk",
      true
    );
  } catch (e: any) {
    return null;
  }
};
export const GetRemoteLoginStatus = async (Payload: any) => {
  return await clientCall(
    `cashmgmt/duo/auth`,
    { method: "POST", body: Payload },
    appConfig.apiUrls.micro,
    "kjjkjilk",
    true
  );
};
export const UpdateVoidStatus = async (voidobj: any) => {
  const TempAccessToken = "QAToken";
  return await clientCall(
    `agreement/void/update`,
    { method: "PUT", body: voidobj },
    agreementDevUrl,
    TempAccessToken
  );
};

export const GetFscContestDetail = async (payload: any) => {
  let url: any = `fsc/info?isAllDataRequired=${payload.isAllDataRequired}&type=${payload.type}`;
  if (payload.operationCommunicationId) {
    url += `&operationCommunicationId=${payload.operationCommunicationId}`;
  }
  try {
    // eslint-disable-next-line no-console
    console.log("appConfig.apiUrls.dashboard", appConfig.apiUrls.dashboard);
    const TempAccessToken = "QAToken";
    return await client(
      url,
      { method: "POST", body: payload },
      appConfig.apiUrls.dashboard,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const UpdateFscContestDetail = async (payload: any) => {
  try {
    const TempAccessToken = "QAToken";
    return await client(
      `fsc/manage`,
      { method: "POST", body: payload },
      appConfig.apiUrls.dashboard,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};

export const GetDropDownVals = async (type: any) => {
  try {
    const TempAccessToken = "QAToken";
    return await client(
      `association/details?hierarchyType=${type}`,
      { method: "POST" },
      appConfig.apiUrls.dashboard,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const getStateDropDown = async () => {
  const TempAccessToken = "QAToken";
  return await clientCall(
    `customer/state`,
    { method: "GET" },
    appConfig.apiUrls.customer,
    // `https://dev-customer-racpad.rentacenter.com/api`,
    TempAccessToken
  );
};
export const getStoreLookUpDetails = async (payload: any) => {
  const TempAccessToken = "QAToken";
  return await clientCall(
    `store/lookup`,
    { method: "POST", body: payload },
    appConfig.apiUrls.dashboard,
    TempAccessToken
  );
};
export const getDapEvent = async (payload: any) => {
  /* eslint-disable no-console */
  try {
    return await clientCall(
      `event/search`,
      { method: "POST", body: payload },
      appConfig.apiUrls.micro,
      "QAToken",
      true
    );
  } catch (err: any) {
    return err;
    //throw err;
  }
};
export const getStoreTask = async (payload: any) => {
  /* eslint-disable no-console */
  try {
    return await clientCall(
      `task/${payload}`,
      { method: 'GET' },
      appConfig.apiUrls.micro,
      "QAToken",
       true
    );
  } catch (err: any) {
    return err;
    //throw err;
  }
};
export const getStoreTaskHistory = async (payload: any) => {
  /* eslint-disable no-console */
  try {
    return await clientCall(
      `task/history/${payload}`,
      { method: 'GET' },
      appConfig.apiUrls.micro,
      "QAToken",
       true
    );
  } catch (err: any) {
    return err;
    //throw err;
  }
};
export const searchStoreTask = async (payload: any) => {
  /* eslint-disable no-console */
  try {
    return await clientCall(
      `task/search`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      "QAToken",
       true
    );
  } catch (err: any) {
    return err;
    //throw err;
  }
};
export const createStoreTask = async (payload: any) => {
  /* eslint-disable no-console */
  try {
    return await clientCall(
      `task/create`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      "QAToken",
       true
    );
  } catch (err: any) {
    return err;
    //throw err;
  }
};
export const getSalesPersonOptions = async (
  StoreNumber: any
) => {
  const customerId="12345"
  return await clientCall(
    `agreement/options/${customerId}?storeNumber=${StoreNumber}&isSwitchOut=1`,
    { method: 'GET' },
    appConfig.apiUrls.agreement,
    "QAToken",
    true
  );
};
export const updateEventStatus = async (payload: any) => {
  /* eslint-disable no-console */
  try {
    return await clientCall(
      `appointment/action`,
      { method: "POST", body: payload },
      appConfig.apiUrls.micro,
      "QAToken",
      true
    );
  } catch (err: any) {
    return err;
    //throw err;
  }
};

export const getTimeSlot = async (Payload: any) => {
  return await clientCall(
    "agreement/timeslots",
    { method: "POST", body: Payload },
    appConfig.apiUrls.agreement
  );
};
export const EventHistory = async (Payload: any) => {
  return await clientCall(
    "event/history",
    { method: "POST", body: Payload },
    appConfig.apiUrls.micro,
    "QAToken",
    true
  );
};
export const getAgreementDelivery = async (agreementId: any, appointmentId?:any) => {
  return await clientCall(
    `agreement/delivery/info/${agreementId}?appointmentId=${appointmentId}`,
    { method: "Get" },
    appConfig.apiUrls.agreement,
    "QAToken",
    true
  );
};
export const getAgreementInfo = async (AgreementId: any) => {
  return await clientCall(
    `agreement/${AgreementId}`,
    { method: "GET" },
    appConfig.apiUrls.agreement,
    "QAToken",
    true
  );
};
export const getnextpaymentamount = async (Payload: any) => {
  return await clientCall(
    `agreement/getnextpaymentamount`,
    { method: "POST", body: Payload },
    appConfig.apiUrls.agreement,
    "QAToken",
    true
  );
};
export const LoadDeliveryreceipt = async (agreementId: string) => {
  const TempAccessToken = "QAToken";
  return await clientCall(
    `agreement/delivery/receipt/${agreementId}`,
    { method: "GET" },
    appConfig.apiUrls.agreement,
    TempAccessToken
  );
};
export const GenerateReceipt = async (bodyobj: any) => {
  const TempAccessToken = "QAToken";
  return await clientCall(
    `agreement/generatereceipt`,
    { method: "POST", body: bodyobj },
    appConfig.apiUrls.agreement,
    TempAccessToken
  );
};
export const Confirmdelivery = async (updatedeliverystatus: any) => {
  const TempAccessToken = "QAToken";
  return await clientCall(
    `agreement/delivery/status/update`,
    { method: "PUT", body: updatedeliverystatus },
    appConfig.apiUrls.agreement,
    TempAccessToken
  );
};
export const LoadswitchoutDeliveryreceipt = async (agreementId: string) => {
  const TempAccessToken = "QAToken";
  return await clientCall(
    `agreement/delivery/receipt/${agreementId}?isSwitchOut=1`,
    { method: "GET" },
    appConfig.apiUrls.agreement,
    TempAccessToken
  );
};
export const manageAppointment = async (Payload: any) => {
  return await clientCall(
    "event/manage",
    { method: "POST", body: Payload },
    appConfig.apiUrls.micro,
    'QAToken',
    true
  );
};
export const GetReferenceValues = async (param: string) => {
  try {
    const TempAccessToken = "QAToken";
    return await client(
      `reference?referenceName=${param}`,
      { method: "GET" },
      appConfig.apiUrls.cashmgmt,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const getInventoryServicingDetails = async (serviceNumber: any) => {
  return await clientCall(
    `inventory/service/details/${serviceNumber}`,
    { method: "GET" },
    InventoryBaseUrl,
    "QAToken",
    true
  );
};
export const postAppointment = async (Payload: any) => {
  return await clientCall(
    "agreement/delivery",
    { method: "POST", body: Payload },
    appConfig.apiUrls.agreement,
    "QAToken",
    true
  );
};
export const managePoAcimaFn = async (bodyobj: any) => {
  return await clientCall(
    `manage/${bodyobj.purchaseOrderNumber}`,
    { method: 'POST', body: bodyobj },
    appConfig.apiUrls.acimapo,
    'QAToken',
    true
  );
};
export const InventoryServicing = async (value: any) => {
  return await clientCall(
    `inventory/service/update`,
    { method: "POST", body: value },
    InventoryBaseUrl,
    "QAToken",
    true
  );
};
export const CoworkerRequired = async (Payload: any) => {
  return await clientCall(
    "store-details",
    { method: "POST", body: Payload },
    appConfig.apiUrls.micro,
    "QAToken",
    true
  );
};
export const LoadInventoryInformation = async (
  inventoryNumber: any,
  type?: any
) => {
  // eslint-disable-next-line no-console
  console.log("invinforeq", inventoryNumber);
  // eslint-disable-next-line no-console
  console.log("type", type);

  return await clientCall(
    `inventory/${inventoryNumber}`,
    { method: "GET" },
    InventoryBaseUrl,
    "QAToken",
    true
  );
};
export const getServiceOptions = async (payload: any) => {
  try {
    return await clientCall(
      "inventory/package/list?isService=1",
      { method: "POST", body: payload },
      InventoryBaseUrl,
      "QAToken",
      true
    );
  } catch (err: any) {
    /* eslint-disable no-console */
    console.log(err);
    /* eslint-enable no-console */
    return "Error";
  }
};
